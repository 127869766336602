<template>
	<div class="cart checkout">
		<div class="container" v-if="$api.cart && $api.cart.count">
			<form @submit.prevent="sendOrder">
				<h2 class="background-heading font-brown-semi">Košík</h2>
				<router-link :to="{ name: 'Cart' }" class="d-md-none top-back-button">
					<Button color="brown" type="button" :rtl="true">
						Zpět
					</Button>
				</router-link>
				<div class="form">
					<!-- CONTACT INFORMATION -->
					<div class="row">
						<div :class="requireShippingAddress ? 'col-12' : 'col-md-6'">
							<h3 class="italic font-brown" ref="contact-form-heading">Kontaktní údaje</h3>
							<div class="form-group">
								<div class="row">
									<div class="row col-12">
										<div class="form-field col-md-8" v-if="!requireShippingAddress">
											<label for="checkout-shipping-name">Jméno a příjmení</label>
											<input type="text" id="checkout-shipping-name"
												   v-model="$storage.checkout.shipping['name']"
												   @input="shippingInput('name')"
												   ref="contact-name"
											>
										</div>
										<div class="form-field" :class="requireShippingAddress ? 'col-lg-4 col-xl-3' : 'col-xl-6'">
											<label for="checkout-email">E-mail</label>
											<input type="text" id="checkout-email"
												   v-model="$storage.checkout.contact.email"
												   ref="contact-email">
										</div>
										<div class="form-field" :class="requireShippingAddress ? 'col-lg-4 col-xl-3 offset-lg-1' : 'col-xl-6'">
											<label for="checkout-phone">Telefon</label>
											<input type="text" id="checkout-phone"
												   v-model="$storage.checkout.contact.phone"
												   ref="contact-phone">
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- SHIPPING ADDRESS -->
						<div class="col-md-6" v-if="requireShippingAddress" ref="shipping-form-heading">
							<h3 class="italic font-brown">Dodací údaje</h3>
							<div class="form-group">
								<div class="row">
									<div class="col-12 row">
										<div class="form-field" :class="addressFieldsGrid[id]" v-for="(label, id) in shippingFields" :key="id">
											<label :for="'checkout-shipping-' + id">{{ label}}</label>
											<input type="text" :id="'checkout-shipping-' + id" v-model="$storage.checkout.shipping[id]"
												@input="shippingInput(id)" :ref="'shipping-' + id"
												:readonly="isGibon && (id === 'zip' || id === 'town')"
                        v-if="id !== 'country'">
                      <select v-else :id="'checkout-shipping-' + id" v-model="$storage.checkout.shipping[id]"
                        @change="shippingInput(id)" :ref="'shipping-' + id">
                        <option v-for="country in $api.shippingCountries" :key="country">{{ country }}</option>
                      </select>
										</div>
									</div>
									<div class="col-xl-7 change-town" v-if="isGibon">
										<span @click="changeTown">Změnit město</span>
									</div>
									<div class="col-12">
										<label class="custom-check">
											<span class="label">
												Dodací údaje se liší od fakturačních
											</span>
											<input type="checkbox" name="payment" v-model="useBillingAddress">
											<span class="marker"></span>
										</label>
									</div>
								</div>
							</div>
						</div>

						<!-- BILLING ADDRESS -->
						<div class="col-md-6" ref="billing-form-heading" v-if="!requireShippingAddress || useBillingAddress">
							<h3 class="italic font-brown">Fakturační údaje</h3>
							<div class="form-group">
								<div class="row">
									<div class="col-12 row">
										<div v-for="(label, id) in billingFields" :key="id"
											class="form-field" :class="addressFieldsGrid[id]">
											<label :for="'checkout-billing-' + id">{{ label}}</label>
											<input type="text" :id="'checkout-billing-' + id" v-model="$storage.checkout.billing[id]"
												@input="billingInput(id)" :ref="'billing-' + id"
                        v-if="id !== 'country'">
                      <select v-else :id="'checkout-billing-' + id" v-model="$storage.checkout.billing[id]"
                        @change="billingInput(id)" :ref="'billing-' + id">
                        <option v-for="country in $api.shippingCountries" :key="country" :value="country">{{ country }}</option>
                      </select>
										</div>
									</div>
                  <div class="col-12">
                    <label class="custom-check">
											<span class="label">
												Nakupuji na firmu
											</span>
                      <input type="checkbox" name="payment" v-model="useCompanyBillingAddress">
                      <span class="marker"></span>
                    </label>
                  </div>
                  <div class="col-12 row" v-show="useCompanyBillingAddress">
                    <div v-for="(label, id) in billingFieldsCompany" :key="id"
                         class="form-field" :class="addressFieldsGrid[id]">
                      <label :for="'checkout-billing-' + id">{{ label}}</label>
                      <input type="text" :id="'checkout-billing-' + id" v-model="$storage.checkout.billing[id]"
                             @input="billingInput(id)" :ref="'billing-' + id"
                             v-if="id !== 'country'">
                      <select v-else :id="'checkout-billing-' + id" v-model="$storage.checkout.billing[id]"
                              @change="billingInput(id)" :ref="'billing-' + id">
                        <option v-for="country in $api.shippingCountries" :key="country" :value="country">{{ country }}</option>
                      </select>
                    </div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row cart-bottom">
					<div class="col-md-7 col-lg-8">
						<!--
						<label class="custom-check">
							<span class="label">
								Chci vytvořit účet
							</span>
							<input type="checkbox" name="payment">
							<span class="marker"></span>
						</label>
						-->
            <label class="custom-check">
							<span class="label">
								Jsem si vědom toho, že ginger shoty musí být skladovány v lednici.
							</span>
              <input type="checkbox" name="payment" v-model="fridge">
              <span class="marker"></span>
            </label>
						<label class="custom-check">
							<span class="label">
								Souhlasím s <router-link to="/obchodni-podminky" target="_blank" rel="noopener">obchodními podmínkami</router-link>
								a se <router-link to="/gdpr" target="_blank" rel="noopener">zpracováním osobních údajů</router-link>.
							</span>
							<input type="checkbox" name="payment" v-model="agreement">
							<span class="marker"></span>
						</label>
					</div>
					<div class="col-md-5 col-lg-4 final-price-wrapper">
						<TotalPrice/>
					</div>
				</div>
				<div class="continue-button">
					<router-link :to="{ name: 'Cart' }" class="d-none d-md-block">
						<Button color="brown" type="button" :rtl="true">
							Zpět
						</Button>
					</router-link>
					<Button color="brown" type="submit" :loading="sending">
						Dokončit objednávku
					</Button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "../../event-bus";
	import Button from "../components/Button";
	import TotalPrice from "./Cart/TotalPrice";
  import google from "../../google";

	export default {
		name: "Checkout",
		metaInfo: {
			title: "Košík",
			meta: [{
				name: "description",
				content: "Čerstvé ginger shoty vyrobené metodou cold-press z pečlivě vybraných surovin světového původu. Navíc vše v bio kvalitě. Už jste ochutnali? Originální receptura - základní stavební kámen silné imunity a energie.",
			}]
		},
		components: {
			TotalPrice,
			Button
		},
		data() {
			return {
				agreement: null,
        fridge: null,
				sending: false,
				billingFields: {
					name: "Jméno a příjmení",
					address: "Ulice a číslo popisné",
					town: "Město",
					zip: "PSČ",
          country: "Země",
				},
        billingFieldsCompany: {
          company: "Název firmy",
          cin: "IČ",
          vatin: "DIČ"
        },
				shippingFields: {
					name: "Jméno a příjmení",
					address: "Ulice a číslo popisné",
					town: "Město",
					zip: "PSČ",
          country: "Země",
				},
				useBillingAddress: false,
        useCompanyBillingAddress: false,
				billingFieldsAltered: {
					name: false,
					address: false,
					town: false,
					zip: false,
          country: false,
				},

				// Grid for billing fields when shipping address is not present
				addressFieldsGrid: {
					name: "col-lg-8",
					address: "col-12",
					town: "col-md-7 col-lg-8",
					zip: "col-md-5 col-lg-4",
          country: "col-12",
          company: "col-lg-8",
					cin: "col-lg-6",
					vatin: "col-lg-6"
				},
				errors: {
					UNKNOWN: "Při odesílání objednávky došlo k chybě, zkuste to prosím znovu",
					EMPTY_CART: "Váš košík je prázdný",
					SHIPPING_NOT_FOUND: "Nevybrali jste způsob dopravy",
					PAYMENT_NOT_FOUND: "Nevybrali jste způsob platby",
					SHIPPING_NOT_SET: "Neplatný způsob dopravy",
					PAYMENT_NOT_SET: "Neplatný způsob platby",
					INVALID_DISCOUNT: "Vybraný voucher již není platný",
					INVALID_PRODUCT_PRICE: "Ceny vybraných produktů se změnily",
					INVALID_PICKUP_POINT: "Vyberte prosím výdejnu pro doručení",
					INVALID_EMAIL: "Zadaný e-mail je neplatný",
					INVALID_PHONE: "Zadaný telefon je neplatný",
					INVALID_NAME_COMPANY: "Vyplňte prosím jméno a příjmení nebo název firmy",
					INVALID_ADDRESS: "Zadejte prosím ulici i číslo popisné",
					INVALID_TOWN: "Zadané město je neplatné",
					INVALID_ZIP: "Zadané PSČ nemá správný formát",
          INVALID_COUNTRY: "Zadaná země není platná",
				}
			};
		},
		computed: {
			requireShippingAddress() {
				return this.$storage.cart.shipping.required &&
					!this.$api.cart.shipping[this.$storage.cart.shipping.type].pickup;
			},

      requireBillingAddress() {
        return this.$api.cart.subscriptionDuration;
      },

			isGibon() {
				return this.$storage.cart.shipping.type === "gibon-delivery";
			},
		},
		mounted() {
			EventBus.$emit("changeTheme", "white");
      google.checkout(2);
		},
		methods: {
			sendOrder() {
				if (this.sending) return;

				let data = this.$storage.checkout;

				if (!data.contact.email || !data.contact.email.trim())
					return this.validationError("contact", "e-mailovou adresu", "email");

				if (!data.contact.phone || !data.contact.phone.trim())
					return this.validationError("contact", "telefon", "phone");

				if (!data.shipping.name || !data.shipping.name.trim()) {
					if (this.requireShippingAddress)
						return this.validationError("shipping", "dodací jméno", "name");
					return this.validationError("contact", "kontaktní jméno", "name");
				}

				if (this.requireShippingAddress)
					if (!this.validatePersonalDetails(data.shipping, "shipping", "dodací"))
						return false;

				if (this.requireBillingAddress)
				  if (!this.validatePersonalDetails(data.billing, "billing", "fakturační"))
					  return false;

				if (!this.agreement)
					return EventBus.$emit("flash", "Musíte souhlasit s obchodními podmínkami", "error");

        if (!this.fridge)
          return EventBus.$emit("flash", "Musíte souhlasit se skladováním v lednici", "error");

				this.sending = true;

				this.$api.sendOrder()
					.then(data => {
						let route;
            let query = {};
						if (data.paid)				// Order already paid by voucher
							route = "OrderDone";
						else if (!data.payment_url) {	// No payment URL available -> bank payment
              route = "OrderBankPayment";

              if (data.is_subscription) {
                query.predplatne = "true";
              }
            } else						// Online payment
							route = "OrderOnlinePayment";

						this.$router.push({
							name: route,
              query,
						});
					})
					.catch(message => {
						if (!message || typeof message !== "string")
							message = "UNKNOWN";

						EventBus.$emit("flash", this.errors[message], "error")
					})
					.finally(() => {
						this.sending = false;
					});
			},

			validatePersonalDetails(data, section, messagePrefix) {
        // Vyplňte prosím [...]
				let fields = {
					"address": "ulici a číslo",
					"town": "město",
					"zip": "PSČ",
          "country": "zemi",
				};

				if (section === "shipping")
					fields["name"] = "jméno";

				for (let index in fields)
					if (!data[index] || !data[index].trim())
						return this.validationError(section, messagePrefix + " " + fields[index], index);

				return true;
			},

			validationError(section, message, field = null) {
				EventBus.$emit("flash", "Vyplňte prosím " + message, "error");

				let top = this.$refs[section + "-form-heading"].offsetTop;
				top += window.innerWidth > 991.98 ? 90 : -70;

				this.$smoothScroll(top, () => {
					if (field) {
						let ref = this.$refs[section + "-" + field];

						if (typeof ref === "undefined")
							return;

						if (Array.isArray(ref)) {
							ref = ref[0];

							if (!ref)
								return;
						}

						ref.focus();
					}
				});

				return false;
			},

			// Field for shipping address input event
			shippingInput(field) {
				// Billing field not altered yet -> keep in sync with shipping value
				if (!this.billingFieldsAltered[field])
					this.$storage.checkout.billing[field] = this.$storage.checkout.shipping[field];
			},

			// Field for billing address input event
			billingInput(field) {
				if (!this.requireShippingAddress && field !== "name")
					this.$storage.checkout.shipping[field] = this.$storage.checkout.billing[field];

				// Mark field as altered to turn off sync with shipping value
				this.billingFieldsAltered[field] = true;
			},

			changeTown() {
				EventBus.$emit("toggleDeliveryModal", true);
			}
		}
	}
</script>
