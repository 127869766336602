<template>
	<div class="products-table">
		<div class="tr thead">
			<div class="th">Produkt</div>
			<div class="th th-quantity">Počet</div>
			<div class="th th-price">Cena</div>
			<div class="th">Celkem</div>
			<div class="th"></div>
		</div>
		<div class="tr" v-for="(product, id) in $api.cart.content" :key="id">
			<div class="td td-description">
				<img :src="product.options.image" alt="Produkt" v-if="product.options.image" loading="lazy">
				<span>{{ product.name }}</span>
			</div>
			<div class="td td-quantity">
				<div class="quantity-field">
          <button @click="changeQuantity(id, product.qty - 1)">‒</button>
					<span class="quantity">
            {{ product.qty }}
            {{ product.options.unit === "pack" && product.qty != 1 ? "packs" : product.options.unit }}
					</span>
					<button @click="changeQuantity(id, product.qty + 1)">+</button>
				</div>
			</div>
			<div class="td td-price">
				{{ $price(product.price) }} / {{ product.options.unit }}
			</div>
			<div class="td td-sum">{{ $price(product.subtotal) }}</div>
			<div class="td td-remove">
				<span @click="removeProduct(id)">
					✕
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {EventBus} from "../../../event-bus";

	export default {
		name: "ProductsTable",
		data() {
			return {
				qtyUpdateThrottle: 1,	// time in seconds to throttle quantity update API request
				updatedQty: {
					id: null,
					qty: null,
					timeout: null
				}
			};
		},
		methods: {
			changeQuantity(id, qty) {
				if (qty < 1)
					return;

				// Another product was waiting for quantity request settlement -> settle right away
				if (this.updatedQty.id && this.updatedQty.id !== id)
					this.settleQuantityUpdate();

				clearTimeout(this.updatedQty.timeout);

				this.updateCachedCartQty(id, qty);
				this.updatedQty = {
					id, qty,
					timeout: setTimeout(this.settleQuantityUpdate, this.qtyUpdateThrottle * 1000)
				};
			},

			settleQuantityUpdate() {
				let id = this.updatedQty.id;

				if (!this.$api.cart.content[id])
					return;

				this.$api.updateCartQty(id, this.updatedQty.qty)
					.catch(() => {
						EventBus.$emit("flash", "Množství produktu se nepodařilo změnit, zkuste to prosím znovu", "error");
					})
					.finally(() => {
						if (id === this.updatedQty.id)	// No other item is throttling
							this.updatedQty.id = null;
						else							// Other item is throttling -> prevent quantity overwrite with new API data
							this.updateCachedCartQty(this.updatedQty.id, this.updatedQty.qty);
					});
			},

			// Pseudo update cart product quantity so it can be shown in view without having to wait for API
			updateCachedCartQty(id, qty) {
				if (typeof this.$api.cart.content[id] === "object") {
					this.$api.cart.content[id].qty = qty;
					this.$api.cart.content[id].subtotal = qty * this.$api.cart.content[id].price;
				}

				this.$set(this.$api.cart, "subtotal", null);
				this.$set(this.$api.cart, "total", null);
				this.$set(this.$api.cart, "realTotal", null);
			},

			removeProduct(id) {
				this.updateCachedCartQty(id, 0);
				this.$delete(this.$api.cart.content, id);
				this.$set(this.$api.cart, "count", this.$api.cart.count-1);
				this.$api.removeFromCart(id)
					.catch(() => {
						EventBus.$emit("flash", "Produkt se nepodařilo odebrat, zkuste to prosím znovu", "error");
					});
			}
		}
	}
</script>
