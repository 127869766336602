<template>
  <div class="ppl-modal">
    <button class="close-button" @click="pickupSelected({ detail: null })">
      <Icon src="close" />
    </button>
    <div id="ppl-parcelshop-map" data-countries="cz,sk" data-initialfilters="ParcelBox"></div>
    <!-- <component> used to workaround Vue removing scripts from components -->
    <component v-bind:is="'script'" defer
               type="text/javascript" src="https://www.ppl.cz/sources/map/main.js" async></component>
    <component v-bind:is="'link'" rel="stylesheet" href="https://www.ppl.cz/sources/map/main.css" />
  </div>
</template>

<script>
import Icon from '@/views/components/Icon.vue';

export default {
  name: 'PPLModal',
  components: { Icon },
  mounted() {
    document.addEventListener("ppl-parcelshop-map", this.pickupSelected);
  },
  destroyed() {
    document.removeEventListener("ppl-parcelshop-map", this.pickupSelected);
  },
  methods: {
    pickupSelected(event) {
      this.$emit('selected', event.detail);
    },
  },
}
</script>